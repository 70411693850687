/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "../../core/has.js";
import { clamp as t } from "../../core/mathUtils.js";
export { createEmptyImageData } from "../../core/imageUtils.js";
const h = 2048,
  i = 1.5,
  e = 8;
function o(h, i) {
  const {
      format: e,
      quality: o,
      rotation: n,
      disableDecorations: a
    } = h || {},
    r = b(h, i.padding),
    l = p(h, {
      width: i.width - r.left - r.right,
      height: i.height - r.top - r.bottom
    }),
    {
      width: g,
      height: f
    } = m(h, l),
    d = j(e),
    u = R[d];
  return {
    format: d,
    quality: t(null != o ? o : u, 0, 100),
    area: l,
    width: g,
    height: f,
    rotation: n,
    disableDecorations: !!a,
    ignoreBackground: !!h?.ignoreBackground,
    ignorePadding: !!h?.ignorePadding
  };
}
function n(t, h) {
  const i = o(t, h),
    e = i.area,
    n = i.width / e.width,
    a = b(i, h.padding),
    r = a.left + a.right,
    l = a.top + a.bottom,
    g = h.width - r,
    f = h.height - l,
    d = Math.floor(g * n + r),
    u = Math.floor(f * n + l),
    c = t?.layers ?? [],
    s = i.ignoreBackground,
    w = i.ignorePadding;
  return {
    framebufferWidth: d,
    framebufferHeight: u,
    region: {
      x: Math.floor(e.x * n) + a.left,
      y: Math.floor(e.y * n) + a.top,
      width: i.width,
      height: i.height
    },
    format: i.format,
    quality: i.quality,
    rotation: i.rotation,
    pixelRatio: n,
    layers: c,
    disableDecorations: i.disableDecorations,
    ignoreBackground: s,
    ignorePadding: w,
    objectAndLayerIdColor: !1
  };
}
function a(t, h, i) {
  const {
      ctx: e,
      canvas: o
    } = l(t, i),
    n = e.getImageData(0, 0, t.width, t.height),
    a = u(o, h);
  return g(o), {
    dataUrl: a,
    data: n
  };
}
function r(t, h) {
  const {
      ctx: i,
      canvas: e
    } = l(t, h),
    o = i.getImageData(0, 0, t.width, t.height);
  return g(e), o;
}
function l(t, h) {
  const i = f();
  h.premultipliedAlpha && q(t), i.width = t.width, i.height = t.height;
  const e = i.getContext("2d", {
    willReadFrequently: !0
  });
  return e.putImageData(t, 0, 0), h.flipY && D(e), {
    ctx: e,
    canvas: i
  };
}
function g(t) {
  t.width = 0, t.height = 0;
}
function f() {
  return null == d && (d = document.createElement("canvas")), d;
}
let d = null;
function u(t, h) {
  const i = v[h.format],
    e = h.quality / 100;
  return t.toDataURL(i, e);
}
function c(h, i) {
  const e = j(h),
    o = R[e];
  return {
    format: e,
    quality: t(null != i ? i : o, 0, 100)
  };
}
function s(t, h) {
  return h / Math.max(t[0], t[1]);
}
function w(t, h, i, e = 0, o = 0, n = t.width - e, a = t.height - o, r = !1) {
  const {
      data: l
    } = t,
    {
      width: g,
      height: f,
      data: d
    } = h,
    u = n / g,
    c = a / f,
    s = Math.ceil(u / 2),
    w = Math.ceil(c / 2),
    M = t.width;
  for (let m = 0; m < f; m++) for (let t = 0; t < g; t++) {
    const h = 4 * (t + (r ? f - m - 1 : m) * g);
    let n = 0,
      a = 0,
      p = 0,
      x = 0,
      y = 0,
      b = 0;
    const j = (m + .5) * c;
    for (let r = Math.floor(m * c); r < (m + 1) * c; r++) {
      const h = Math.abs(j - (r + .5)) / w,
        g = (t + .5) * u,
        f = h * h;
      for (let d = Math.floor(t * u); d < (t + 1) * u; d++) {
        const t = Math.abs(g - (d + .5)) / s,
          h = Math.sqrt(f + t * t);
        if (h >= 1) continue;
        let u = 2 * h * h * h - 3 * h * h + 1;
        const c = 4 * (e + d + (o + r) * M);
        b += u * l[c + 3], a += u, !i && l[c + 3] < 255 && (u = u * l[c + 3] / 255), p += u * l[c], x += u * l[c + 1], y += u * l[c + 2], n += u;
      }
    }
    d[h] = p / n, d[h + 1] = x / n, d[h + 2] = y / n, d[h + 3] = b / a;
  }
  return h;
}
function M(t, o, n) {
  if (!o) return t;
  const {
      framebufferWidth: a,
      framebufferHeight: r,
      pixelRatio: l,
      region: g
    } = t,
    f = b(t, n),
    d = f.left + f.right,
    u = f.top + f.bottom,
    c = a - d,
    s = r - u,
    w = Math.min(e, Math.min((h - d) / c, (h - u) / s));
  return w < i ? t : {
    ...t,
    framebufferWidth: Math.round(c * w) + d,
    framebufferHeight: Math.round(s * w) + u,
    pixelRatio: l * w,
    resample: {
      region: {
        x: Math.round((g.x - f.left) * w) + f.left,
        y: Math.round((g.y - f.top) * w) + f.top,
        width: Math.round(g.width * w),
        height: Math.round(g.height * w)
      },
      width: a,
      height: r
    }
  };
}
function m(t, h) {
  if (!t) return h;
  const i = t.width,
    e = t.height;
  if (null != i && null != e) return {
    width: Math.floor(i),
    height: Math.floor(e)
  };
  if (null == i && null == e) return h;
  const o = h.width / h.height;
  return null == e ? {
    width: Math.floor(i),
    height: Math.floor(i / o)
  } : {
    width: Math.floor(e * o),
    height: Math.floor(e)
  };
}
function p(t, h) {
  const i = {
    x: 0,
    y: 0,
    width: h.width,
    height: h.height
  };
  if (t?.area) {
    null != t.area.x && (i.x = Math.floor(t.area.x)), null != t.area.y && (i.y = Math.floor(t.area.y));
    const e = null != t.area.width ? Math.floor(t.area.width) : null,
      o = null != t.area.height ? Math.floor(t.area.height) : null;
    if (i.width = h.width - i.x, i.height = h.height - i.y, null != e && null != o) i.width = Math.min(i.width, e), i.height = Math.min(i.height, o);else if (null == e && null != o) {
      const t = Math.min(i.width, e);
      i.height = t / i.width * i.height, i.width = t;
    } else if (null != e && null == o) {
      const t = Math.min(i.height, o);
      i.width = t / i.height * i.width, i.height = t;
    }
  }
  return x(y(i, t), h);
}
function x(t, h) {
  const i = Math.floor(Math.max(t.x, 0)),
    e = Math.floor(Math.max(t.y, 0)),
    o = {
      x: i,
      y: e,
      width: Math.floor(Math.min(t.width, h.width - i)),
      height: Math.floor(Math.min(t.height, h.height - e))
    },
    n = o.width / o.height,
    a = t.width / t.height;
  if (a === n) return o;
  if (a > n) {
    const t = Math.floor(o.width / a),
      h = o.height - t;
    return {
      x: o.x,
      y: Math.floor(o.y + h / 2),
      width: o.width,
      height: t
    };
  }
  const r = Math.floor(o.height * a),
    l = o.width - r;
  return {
    x: Math.floor(o.x + l / 2),
    y: o.y,
    width: r,
    height: o.height
  };
}
function y(t, h) {
  if (null == h?.width || null == h.height) return t;
  const i = h.width / h.height,
    e = t.width / t.height;
  if (e === i) return t;
  if (e < i) {
    const h = Math.floor(t.height * i);
    return t.x -= (h - t.width) / 2, t.width = h, t;
  }
  const o = Math.floor(t.width / i);
  return t.y -= (o - t.height) / 2, t.height = o, t;
}
function b(t, h) {
  return !h || t?.ignorePadding ? k : h;
}
function j(t) {
  switch (t) {
    case "png":
    case "jpg":
    case "jpeg":
      return t;
    default:
      return P;
  }
}
function D(t) {
  t.save(), t.globalCompositeOperation = "copy", t.scale(1, -1), t.translate(0, -t.canvas.height), t.drawImage(t.canvas, 0, 0), t.restore();
}
function q(t) {
  const h = t.data,
    i = h.length;
  for (let e = 0; e < i; e += 4) {
    const t = h[e + 3];
    if (255 !== t && t > 0) {
      const i = 255 / t;
      h[e] = h[e] * i, h[e + 1] = h[e + 1] * i, h[e + 2] = h[e + 2] * i;
    }
  }
}
const v = {
    png: "image/png",
    jpg: "image/jpeg",
    jpeg: "image/jpeg"
  },
  I = 98,
  P = "png",
  R = {
    png: 100,
    jpg: I,
    jpeg: I
  },
  k = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
export { o as completeUserSettings, a as encode, r as encodeData, c as getFormatAndQuality, s as getMaximumResolutionScale, w as resampleHermite, M as screenshotSuperSampleSettings, u as toDataUrl, n as toRenderSettings };